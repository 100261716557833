import React, { useEffect } from "react";
import { Box, Slider, Typography } from "@mui/material";
import { Product } from "../../__generated__/graphql";
import { useQuery, gql } from "@apollo/client";
import { numberWithZero } from "../../formaters/numberWithZero";

type Props = {};

interface ProductChoice {
  product: Product;
  amount: number;
}

export const BenefitCalculator = (props: Props) => {
  const productsQuery = gql(
    `query Products{
        products {
            _id
            name
            description
            image
            organisationRevenu
        }
    }`
  );
  const { loading, error, data } = useQuery(productsQuery);
  const [productChoices, setProductChoices] = React.useState<ProductChoice[]>(
    []
  );
  const [numberOfSellers, setNumberOfSellers] = React.useState<number>(25);

  useEffect(() => {
    const products: Product[] = data?.products || [];
    if (!products) return;
    setProductChoices(
      products.map((product) => {
        return { product, amount: 10 };
      })
    );
  }, [data]);

  if (loading) return <p>Loading...</p>;

  if (!productChoices) return <p>No products</p>;
  return (
    <Box>
      <Typography variant="h6" paddingLeft={6} paddingRight={6}>
        Ni tjänar{" "}
        <strong>
          {productChoices.reduce(
            (acc, pc) =>
              Math.round(
                acc +
                  pc.product.organisationRevenu * pc.amount * numberOfSellers
              ),
            0
          )}{" "}
          kr
        </strong>{" "}
        om ni säljer:
      </Typography>
      <Box paddingX={6}>
        <Typography variant="h6" paddingTop={3}>
          Antal säljare: {numberOfSellers}
        </Typography>
        <Box>
          <Slider
            value={numberOfSellers}
            onChange={(event, value) => {
              if (value === undefined) return;
              if (typeof value !== "number") return;
              setNumberOfSellers(value);
            }}
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={40}
          />
        </Box>
      </Box>
      <Box padding={6}>
        {productChoices.map((productChoice, index) => (
          <div key={`product-calculator-${productChoice.product._id}`}>
            <Typography variant="h4" key={index}>
              {numberWithZero(productChoice.amount)} st{" "}
              {productChoice.product.name} ger{" "}
              {Math.round(
                productChoice.product.organisationRevenu *
                  numberOfSellers *
                  productChoice.amount
              )}{" "}
              kr
            </Typography>
            <Slider
              value={productChoices[index].amount}
              onChange={(event, value) => {
                setProductChoices((prev) => {
                  return prev.map((pc, i) => {
                    if (index === i) {
                      return { ...pc, amount: value };
                    } else {
                      return pc;
                    }
                  }) as ProductChoice[];
                });
              }}
              valueLabelDisplay="auto"
              step={1}
              min={0}
              max={40}
            />
          </div>
        ))}
      </Box>
    </Box>
  );
};
