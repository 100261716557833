import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import header from "../assets/images/banner/b009.jpg";
import { BenefitCalculator } from "../components/benefitCalculator/BenefitCalculator";
// import logo from "../assets/images/logo/4x/Asset 1@4x.png";
import ParagraphTitle from "../components/paragraphTitle/ParagraphTitle";
import vanille from "../assets/images/vanille.jpg";
import FlButton from "../components/button/FlButton";
import StartOverlay from "../components/startOverlay/StartOverlay";
import ProgressBadge from "../components/progressBadge/ProgressBadge";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const Home = () => {
  const [startOverlayVisible, setStartOverlayVisible] = useState(false);
  const closeOverlay = () => setStartOverlayVisible(false);
  return (
    <>
      <CssBaseline />
      <StartOverlay visible={startOverlayVisible} close={closeOverlay} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          minHeight: "calc(100vh - 68.5px)",
        }}
      >
        <Container fixed disableGutters>
          <div
            style={{
              backgroundImage: `url(${header})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              aspectRatio: "300/200",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: 2,
                backgroundColor: "rgba(170, 152, 118, 0.5)",
                boxShadow: 4,
                marginBottom: "50px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
              display={{ xs: "none", md: "block" }}
            >
              <Typography variant="h3" color={"Background"}>
                Tjäna pengar till klassen eller laget
              </Typography>
            </Box>
          </div>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              paddingTop: "12px",
              paddingBottom: "12px",
              // color: "#FFFEEE",
              // fontWeight: "bold",
              backgroundImage:
                "linear-gradient(to right top, #fffeee, #d2cab0, #aa9876, #856741, #603813)",
            }}
          >
            <Typography variant="h5" color={"#FFFEEE"}>
              Alla älskar vanilj!
            </Typography>
          </div>
        </Container>
        <Container
          sx={{
            backgroundColor: "white",
            paddingX: { xs: 0, sm: 0 },
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(245, 206, 0, 0.5)",
              paddingBottom: "24px",
            }}
            display={{ xs: "block", sm: "block", md: "none" }}
          >
            <Typography
              variant="h4"
              paddingTop={6}
              paddingLeft={6}
              paddingRight={6}
            >
              Tjäna pengar till klassen eller laget
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{
              paddingTop: { xs: 2, sm: 2, md: 8 },
              paddingLeft: { xs: 2, sm: 2, md: 12 },
              paddingRight: { xs: 2, sm: 2, md: 12 },
            }}
          >
            Med oss är det lätt att tjäna pengar till klassen eller laget.
            Vanilj är en omtyckt och populär krydda. Det är en eftertraktad och
            lyxig produkt som är lätt att sälja.
          </Typography>
          <Box
            sx={{
              // backgroundColor: "rgba(245, 206, 0, 0.5)",
              // paddingY: "24px",
              display: "block",
              textAlign: "center",
            }}
          >
            <img src={vanille} alt="logo" style={{ width: "80%" }} />
          </Box>
          <ParagraphTitle title="Så här funkar det" />
          <ProgressBadge
            text="1"
            backgroundColor="#F5CE00"
            color="#603813"
            style={{ paddingTop: "24px" }}
          >
            <div
              onClick={() => setStartOverlayVisible(true)}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Beställ säljpacket
              <OpenInNewIcon
                style={{
                  fontSize: "2.5rem",
                  verticalAlign: "middle",
                  paddingLeft: "8px",
                }}
              />
            </div>
          </ProgressBadge>

          <ProgressBadge
            text="2"
            backgroundColor="#F5CE00"
            color="#603813"
            style={{ paddingTop: "24px" }}
          >
            Skriv ut beställningsblanketter och dela ut till säljare.
          </ProgressBadge>
          <ProgressBadge
            text="3"
            backgroundColor="#F5CE00"
            color="#603813"
            style={{ paddingTop: "24px" }}
          >
            Sälj våra läckra vaniljstänger till familjemedlemmar och bekanta.
          </ProgressBadge>
          <ProgressBadge
            text="4"
            backgroundColor="#F5CE00"
            color="#603813"
            style={{ paddingTop: "24px" }}
          >
            Samla ifyllda blanketter, sammanställa försäljningar och placera en
            samlad beställning på vår hemsida. <br />
            Ni behåller omedelbart vinsten.
          </ProgressBadge>
          <ProgressBadge
            text="5"
            backgroundColor="#F5CE00"
            color="#603813"
            style={{ paddingTop: "24px" }}
            hideIcon
          >
            Efter det skickar vi en faktura och levererar vaniljprodukterna inom
            1-2 veckor i en säker förpackning.
          </ProgressBadge>
          <ParagraphTitle
            title={`Men hur mycket tjänar vi igentligen?`}
            style={{ marginTop: "40px" }}
          />
          <Typography
            variant="body1"
            sx={{
              paddingTop: { xs: 2, sm: 2, md: 8 },
              paddingLeft: { xs: 2, sm: 2, md: 12 },
              paddingRight: { xs: 2, sm: 2, md: 12 },
            }}
            // paddingBottom={6}
          >
            Er säljare erbjuder våra 2-pack vaniljstänger till försäljning till
            ett pris av 99 kr. Ni genererar ni en intäkt på 44 kr för varje sålt
            2-pack.
          </Typography>
          <Box>
            <Typography variant="h4" p={6} textAlign="center">
              Testa själv!
            </Typography>
            <BenefitCalculator />
          </Box>
          <div
            style={{
              width: "auto",
              display: "flex",
              justifyContent: "center",
              paddingBottom: "50px",
            }}
          >
            <FlButton onClick={() => setStartOverlayVisible(true)}>
              Kom igång nu!
            </FlButton>
          </div>
        </Container>
      </div>
    </>
  );
};
